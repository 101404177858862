import * as React from "react";
import { graphql } from "gatsby";
import Tile from "../components/tile";
import Footer from "../components/footer";
import Helmet from "react-helmet"
import { Box, Container, SimpleGrid} from "@chakra-ui/react";
//import Navigation from "../components/navigation";

const IndexPage = ({ data }) => {

    const siteData = data.site.siteMetadata;
    const siteLogo = `/logos/${siteData.logo}`;
    const tileData = data.allSlideshowsJson.nodes;
    //const catsToShow = process.env["SITE_HOME_CATEGORIES"].split(", ");
    const catsToShow = "Homepage";

    return (

        <Box p={4}>
            <Helmet>
                <title>{siteData.title}</title>
                <script async
                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5555030135342556"
                        crossOrigin="anonymous"></script>
            </Helmet>
            <Container mt={2} maxW="container.lg">
                <div style={{ marginRight: `30px`, marginLeft: `40px`, marginTop: `20px`}}>
                    <a href="/"><img style={{marginLeft: `auto`, marginRight: `auto`}} src={siteLogo} alt={siteData.title} width={160} /></a>
                </div>
                {/*<Navigation/>*/}
            </Container>

            <Container maxW={'6xl'} mt={70}>
                <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 3  }}
                            spacingX={5}
                            spacingY={5}>
                    {tileData.map((tile, i) => {
                        if (catsToShow.includes(tile.category)) {
                            return <Tile key={i} tile={tile} />;
                        }
                    })}
                </SimpleGrid>
            </Container>
            <Footer site={siteData}/>
        </Box>
    );
};

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        description
        siteUrl
        title
        logo
      }
    }
    allSlideshowsJson {
      nodes {
        slug
        title
        category
        slides {
          category
          imageTitle
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
